import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoIosClose } from "react-icons/io";
import ReactGA from "react-ga";
import useGetUTM from "../customHooks/useGetUTM";

// ReactGA.initialize([
//     {
//         trackingId: 'UA-180483140-5',
//         gaOptions: {
//             name: 'oppobrowser'
//         }
//     },
//     {
//         trackingId: 'UA-180483140-7',
//         gaOptions: {
//             name: 'realmebrowser'
//         }
//     },
//     {
//         trackingId: 'UA-180483140-6',
//         gaOptions: {
//             name: 'all'
//         }
//     },
//     {
//         trackingId: "UA-180483140-9",
//         gaOptions: {
//             name: "xiaomi",
//         },
//     },
//     {
//         trackingId: "UA-180483140-10",
//         gaOptions: {
//             name: "airtel",
//         },
//     },

// ]);

const Container = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 9999999999999;
  padding: 8px 8px;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  column-gap: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  display: ${({ show }) => (show ? "flex" : "none")};
  animation: slidein 300ms ease-out;
  p {
    color: #000;
    font-family: var(--heading-font);
    margin: 0;
    font-size: 13px;
  }
  button {
    background-color: var(--primary-color);
    padding: 10px 20px;
    color: #fff;
    border: none;
    outline: none;
    flex-shrink: 0;
    border-radius: 20px;
  }
  img {
    margin-right: 8px;
  }
  @keyframes slidein {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }
`;
const eventGa = (utmSource, category, action, nonInteraction) => (ReactGA.event(
    {
        category: utmSource === "oppobrowser" ? "Oppo_Third" : (utmSource === "realmebrowser" ? "Realme_Third" : "Organic_Third"),
        action: category,
        label: action,
        nonInteraction: nonInteraction
    },
    ['all']
))

const NotifyStrip = () => {
    const [show, setShow] = useState(false);
    const { utmSource } = useGetUTM();
    const [url, setUrl] = useState(" ");

    useEffect(() => {
        setTimeout(() => {
            const check = localStorage.getItem("third_campaign");
            let utmSource = localStorage.getItem("utm_source");//cant use outer utm source here due to empty dependancy closure thingy
            var timeDelay = 360000;
            timeDelay = ((utmSource === "oppobrowser" || utmSource === "realmebrowser"
                || utmSource === "xiaomi" || utmSource === "airtel" || utmSource === "xiaomicalendar") ? 60000 : 15000);

            !check &&
                setTimeout(() => {
                    // if (!(utmSource === "oppobrowser" || utmSource === "realmebrowser")) {
                    if (window.matchMedia("(max-width: 1008px)").matches) {
                        setShow(true);
                        eventGa(utmSource, "Strip", "Shown", false);
                    }
                    // }

                }, timeDelay);
        }, 1000)
    }, []);

    useEffect(() => {
        if (utmSource === "oppobrowser") {
            setUrl("https://thisday.page.link/installoppothreee");
        } else if (utmSource === "realmebrowser") {
            setUrl("https://thisday.page.link/installrealmethree");
        } else {
            setUrl("https://thisday.page.link/installorganicthreee");
        }
    }, [utmSource])

    const close = () => {
        setShow(false);
        eventGa(utmSource, "Button", "Closed", false);
    };
    const switchToApp = () => {
        eventGa(utmSource, "Button", "Get App", false);
        setShow(false);
        localStorage.setItem("third_campaign", "clicked");
    };
    return (
        <Container show={show}>
            <IoIosClose color="#000" fontSize={40} onClick={close} />
            <img src="/icons/ThisDayBlueFont.svg" height={50} />
            <p>For smooth Ad free experience</p>
            <button onClick={switchToApp}>
                <a href={url} target="__blank">Get App</a>
            </button>
        </Container>
    );
};

export default NotifyStrip;
