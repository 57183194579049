import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme, GlobalStyles } from "../src/Theme";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import * as tdtag from "../src/tdtag";
import ReactGA from "react-ga";
// import FloatingSurveyButton from '../components/surveyButton/FloatingSurveyButton';
import NotifyStrip from "../components/campaignOEM/NotifyStrip";
// import Notify from '../components/campaignOEM/Notify'
import Player from "../components/player/Player";
import {
  initPlayerState,
  PlayerContext,
} from "../components/player/playerContext";
import { getLikedPods, loginGuest } from "../components/podcasts/service";
import { bottomNavigationContext, notificationCardContext, dateContext } from "../pages/_app";
import { withLocalStorage } from '../common/hooks/withLocalStorage';

//React GA oppo
ReactGA.initialize([
  {
    trackingId: "UA-180483140-15",
    gaOptions: {
      name: "opera",
      cookieDomain: "thisday.app",
      cookieUpdate: false
    },
  },
]);


//Contexts
// export const bottomNavigationContext = createContext("");
// export const notificationCardContext = createContext("");
// export const dateContext = createContext("");
// export const topicContext = createContext("");
export const USER = 'thisdayuser';

function Main({ Component, pageProps, router, scrollTo, getItem, setItem }) {

  const [showBottomNavigation, setShowBottomNavigation] = useState(false);
  const [showNotificationCard, setShowNotificationCard] = useState(false);
  //count variable to not show notification card for a particular session
  const [count, setCount] = useState(0);
  const [thisDayPlayer, setThisDayPlayer] = useState(initPlayerState);
  const routers = useRouter();

  useEffect(() => {
    const authUserLH = getItem(USER);

    const authenticateAndSetData = async () => {
      const user = await loginGuest();
      if (user) {
        setItem(USER, user.data);
        getSetLikedPodsL(user.data);
      }
    };

    const getSetLikedPodsL = async (authUser) => {
      const data = await getLikedPods(authUser);
      setThisDayPlayer((ps) => ({ ...ps, likedPods: data?.liked_ids || [] }));
    };

    if (
      router.pathname.includes("podcast") ||
      router.pathname.includes("chronicle")
    ) {
      authUserLH ? getSetLikedPodsL(authUserLH) : authenticateAndSetData();
    }
  }, [router.pathname]);

  const handleCount = () => {
    setCount(count + 1);
  };

  const handleNotficationCard = (value) => {
    setShowNotificationCard(value);
  };
  const handleShowBottomNavigation = (value) => {
    setShowBottomNavigation(value);
  };
  //   useEffect(() => {
  //     navigator.serviceWorker.getRegistrations().then(function(registrations) {
  //       for(let registration of registrations) {
  //        registration.unregister()
  //      } })
  // },[])

  //state variable to get date and month the current user is in from Nav
  const [currentPageDate, setCurrentPageDate] = useState(dayjs().format("DD"));
  const [currentPageMonth, setCurrentPageMonth] = useState(
    dayjs().format("MMMM")
  );
  const handleCurrentPageDate = (val) => {
    setCurrentPageDate(val);
  };
  const handleCurrrentPageMonth = (val) => {
    setCurrentPageMonth(val);
  };

  //Dark Mode or Light Mode
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };
  //tdtag

  useEffect(() => {
    const handleRouteChange = (url) => {
      tdtag.pageview(url);
    };

    routers.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      routers.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [routers.events]);
  // useEffect(()=>{console.log(window.history)},[])
  useEffect(() => {
    setTimeout(() => {
      ReactGA.pageview(routers.asPath, ["opera"]);
    }, 1000);

    ReactGA.event(
      {
        category: "TimeOnPage",
        action: "0",
        label: "0-10 seconds",
        nonInteraction: true,
      },
      ["opera"]
    );

    setTimeout(() => {
      ReactGA.event(
        {
          category: "TimeOnPage",
          action: "1",
          label: "11-30 seconds",
          nonInteraction: false,
        },
        ["opera"]
      );
    }, 11000);

    setTimeout(() => {
      ReactGA.event(
        {
          category: "TimeOnPage",
          action: "2",
          label: "31-60 seconds",
          nonInteraction: false,
        },
        ["opera"]
      );
    }, 31000);

    setTimeout(() => {
      ReactGA.event(
        {
          category: "TimeOnPage",
          action: "3",
          label: "61-180 seconds",
          nonInteraction: false,
        },
        ["opera"]
      );
    }, 61000);

    setTimeout(() => {
      ReactGA.event(
        {
          category: "TimeOnPage",
          action: "4",
          label: "181-600 seconds",
          nonInteraction: false,
        },
        ["opera"]
      );
    }, 181000);

    setTimeout(() => {
      ReactGA.event(
        {
          category: "TimeOnPage",
          action: "5",
          label: "601-1800 seconds",
          nonInteraction: false,
        },
        ["opera"]
      );
    }, 601000);

    setTimeout(() => {
      ReactGA.event(
        {
          category: "TimeOnPage",
          action: "6",
          label: "1801+ seconds",
          nonInteraction: false,
        },
        ["opera"]
      );
    }, 1801000);
  }, [routers.asPath]);

  return (
    <>
      <PlayerContext.Provider
        value={{ player: thisDayPlayer, setPlayerState: setThisDayPlayer }}
      >
        <ThemeProvider theme={theme == "light" ? lightTheme : darkTheme}>
          <bottomNavigationContext.Provider
            value={{ handleShowBottomNavigation }}
          >
            <notificationCardContext.Provider
              value={{
                handleNotficationCard,
                showNotificationCard,
                count,
                handleCount,
              }}
            >
              <dateContext.Provider
                value={{
                  currentPageDate,
                  handleCurrentPageDate,
                  currentPageMonth,
                  handleCurrrentPageMonth,
                }}
              >
                {/* <div style={{marginBottom: showBottomNavigation ? '40px' :'0'}}> */}

                {/* <button onClick={toggleTheme}>Switch Theme</button> */}
                {/* {!(router.asPath.includes("/amp"))
                  && !router.route.includes("/reviews/Review")
                  && !router.route.includes("/privacy-policy")
                  && !router.route.includes("/terms-of-service")
                  && !router.route.includes("/chronicles")
                  && <NotifyStrip />} */}
                <Component
                  scrollTo={500}
                  {...pageProps}
                  key={router.asPath}
                  theme={theme}
                />
                <GlobalStyles />
                {/* </div> */}
                {/* {showBottomNavigation ? <BottomNavigationCompo/> : null} */}
              </dateContext.Provider>
            </notificationCardContext.Provider>
          </bottomNavigationContext.Provider>
        </ThemeProvider>
        <Player />
      </PlayerContext.Provider>
    </>
  );
}

export default withLocalStorage(Main);
