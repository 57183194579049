import React from "react";
import playw from "../../public/podcasts/images/playw.svg";
import pausew from "../../public/podcasts/images/pausew.svg";
import EmptyHeart from "../../public/podcasts/images/emptyheart.svg";
import FilledHeart from "../../public/podcasts/images/filledheart.svg";
import x from "../../public/podcasts/images/x.svg";
import { Grid, Slider } from "@material-ui/core";

export default function PlayerMini({
  handlePlayPause,
  player,
  closePlayer,
  onFullScreen,
  onLike,
}) {
  const audio = player.file?.audios?.[0];
  return (
    <div className="this-day-player max-width" onClick={() => onFullScreen(audio.slug)}>
      <Slider
        defaultValue={0}
        value={player.played}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        min={0}
        max={0.9999999}
        className="mini-input-range"
      />
      <Grid
        container
        alignItems="center"
        style={{ padding: 10, height: 'inherit' }}
        flexDirection="column"
      >
        <Grid xs={1} style={{ display: "flex" }}>
          <img
            style={{ borderRadius: 4 }}
            height={47}
            width={47}
            src={audio?.image_url}
          />
        </Grid>
        <Grid xs={8}>
          <div className="mini-player-title">
            {audio?.title?.substring(0, 25)}
            {audio?.title?.length > 25 && "..."}
          </div>
          <div className="mini-player-count">{audio?.played_count || 0} Plays</div>
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          xs={3}
        >
          <div
            onClick={onLike(audio?.id)}
            className="csp"
            style={{ paddingRight: 20 }}
          >
            {player.likedPods?.includes?.(audio?.id) ? (
              <img style={{ height: 25 }} src={FilledHeart} alt="Filled heart" />
            ) : (
              <img style={{ height: 25 }} src={EmptyHeart} alt="Empty heart" />
            )}
          </div>
          <div
            className="csp"
            style={{ paddingRight: !player.playing ? 10 : 0 }}
            onClick={(e) => {
              e.stopPropagation();
              handlePlayPause();
            }}
          >
            <img style={{ height: !player.playing  ? 25 : 30 }} src={!player.playing ? playw : pausew} alt="playicon" />
          </div>
          {!player.playing && (
            <div
              className="csp"
              onClick={(e) => {
                e.stopPropagation();
                closePlayer();
              }}
            >
              <img style={{ height: 30 }} src={x} alt="close-icon" />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
