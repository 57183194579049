import React, { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import { useOnClickOutside } from './hooks/useOnClickOutside';
import DownCaret from "../../public/podcasts/images/downcareticon.svg";
import ShareMusic from "../../public/podcasts/images/sharemusic.svg";
import BackIcon from "../../public/podcasts/images/10backicon.svg";
import ForwordIcon from "../../public/podcasts/images/10forwordicon.svg";
import ForwordIconf from "../../public/podcasts/images/next.svg";
import EmptyHeart from "../../public/podcasts/images/emptyheart.svg";
import FilledHeart from "../../public/podcasts/images/filledheart.svg";
import playicon from "../../public/podcasts/images/playicon.svg";
import pauseIcon from "../../public/podcasts/images/pause.svg";
import { Grid, Typography, Dialog, Slide } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import Share from "../../components/share/Share";

// import Image from "next/image";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function fmtMSS(s) {
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

export default function PlayerFullscreen({
  player,
  setPlayerState,
  handlePlayPause,
  // handleSetPlaybackRate,
  handleSeekChange,
  handleSeekMouseDown,
  handleSeekMouseUp,
  fastForward,
  onLike,
  handleNext,
  handlePrev,
  handlePlaybackRateChange,
}) {
  const router = useRouter();
  const [openPlaybackPop, setOpenPlaybackPop] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const playBackRef = useRef();

  useOnClickOutside(playBackRef, () => setOpenPlaybackPop(false));
  
  const oSpeedMap = {
     0.25: 10,
     0.5: 20,
     1: 30,
     1.25: 40,
     1.5: 50,
     2: 60
  }

  const playBackSliderMarks = [
    {
      value: 10,
      label: "0.25x",
    },
    {
      value: 20,
      label: "0.5x",
    },
    {
      value: 30,
      label: "1x",
    },
    {
      value: 40,
      label: "1.25x",
    },
    {
      value: 50,
      label: "1.5x",
    },
    {
      value: 60,
      label: "2x",
    },
  ];

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        router.push("/podcasts", "/podcasts", { shallow: true, scroll: false });
        setPlayerState((ps) => ({ ...ps, mode: "mini" }));
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]); // Add any state variables to dependencies array if needed.

  const fillSlider = () => {
    for (let e of document.querySelectorAll(
      'input[type="range"].slider-progress'
    )) {
      e.style.setProperty("--value", e.value);
      e.style.setProperty("--min", e.min == "" ? "0" : e.min);
      e.style.setProperty("--max", e.max == "" ? "100" : e.max);
      e.addEventListener("input", () =>
        e.style.setProperty("--value", e.value)
      );
    }
  };

  useEffect(() => {
    fillSlider();
  });

  const audio = player.file?.audios?.[0];
  return (
    <Dialog
      className="max-width player-fullscreen"
      fullScreen
      open={true}
      onClose={() => {}}
      TransitionComponent={Transition}
    >
      <div
        style={{
          backgroundColor: "#161630",
          padding: 20,
          height: "100%",
        }}
      >
        <Grid className="iphoneScreen  iphoneScreen--fix">
          <Grid container className="topBar">
            <Grid py={5} px={2} xs={12} className="topBar--text">
              <span
                className="downarrowabs csp"
                onClick={() => {
                  setPlayerState((ps) => ({ ...ps, mode: "mini" }));
                  router.push("/podcasts", "/podcasts", {
                    shallow: true,
                    scroll: false,
                  });
                }}
              >
                <img src={DownCaret} alt="Down Caret" className="rgtArrow" />
              </span>
              <Typography style={{ color: "#fff" }} flexGrow={1}>
                Now Playing
              </Typography>
            </Grid>
          </Grid>

          <Grid container px={2} pt={2} className="musicPlayInfo">
            <Grid item xs={12} className="musicImage">
              <img src={audio?.image_url} alt="Music image" />
              <Typography variant="h5">
                {audio?.summary?.substring(0, 60)}
                {audio?.summary?.length > 60 && "..."}
              </Typography>
            </Grid>
            <Grid xs={12} className="musicInfo">
              {audio?.played_count} Plays &nbsp;
              <span className="dotforlike"></span>
              {audio?.likes_count} Likes
            </Grid>
          </Grid>
          <div className="fixedtrackInfo">
            <Grid container p={2} className="musicPlayInfo csp">
              <div onClick={onLike(audio?.id)}>
                {player.likedPods?.includes?.(audio?.id) ? (
                  <img src={FilledHeart} alt="Filled heart" />
                ) : (
                  <img src={EmptyHeart} alt="Empty heart" />
                )}
              </div>
              <div>
                <img
                  onClick={() => setOpenShare((s) => !s)}
                  src={ShareMusic}
                  alt="share icon"
                />
                <div className="share-wrap csp">
                  {openShare && (
                    <Share
                      url={`https://thisday.app/podcasts/${audio?.slug}`}
                      title={audio?.title}
                    />
                  )}
                </div>
              </div>
            </Grid>
            <Grid container p={2} className="seekbar mt-20">
              {/* https://toughengineer.github.io/demo/slider-styler/slider-styler.html#download */}
              {/* https://toughengineer.github.io/demo/slider-styler/slider-styler.html#download */}
              {/* https://toughengineer.github.io/demo/slider-styler/slider-styler.html#download */}
              <input
                id="full-input-range-id"
                className="styled-slider slider-progress"
                style={{ width: "100%" }}
                type="range"
                min={0}
                max={0.999999}
                step="any"
                value={player.played}
                onChange={handleSeekChange}
                // onMouseDown={handleSeekMouseDown}
                // onMouseUp={handleSeekMouseUp}
                onPointerDown={handleSeekMouseDown}
                onPointerUp={handleSeekMouseUp}
              />
              <div className="playedInfo">
                <div>{player.playedSeconds ? fmtMSS(Math.floor(player.playedSeconds)) : '0:00'}</div>
                <div>{player.duration ? fmtMSS(Math.floor(player.duration)) : '0:00'}</div>
              </div>
            </Grid>

            <Grid className="track mt-20" pt="4" pb="4" item xs={12}>
              <div className="backTrack csp">
                <img onClick={handlePrev} src={ForwordIconf} alt="BackIcon" />
              </div>
              <div className="Grid playTrack">
                <div
                  onClick={() => fastForward("back", 10, player.playedSeconds)}
                  className="tenBackTrack csp"
                >
                  <img src={BackIcon} height={44} alt="BackIcon" />
                </div>
                <div className="playPause csp" onClick={handlePlayPause}>
                  <img
                    src={!player.playing ? playicon : pauseIcon}
                    alt="playicon"
                    {...(player.playing && { style: {filter: 'invert(1)'} })}
                    {...(player.playing && { style: {filter: 'brightness(0)'} })}

                  />
                </div>
                <div
                  onClick={() => fastForward("front", 10, player.playedSeconds)}
                  className="tenNextTrack csp"
                >
                  <img src={ForwordIcon} height={44} alt="ForwordIcon" />
                </div>
              </div>
              <div onClick={handleNext} className="nextTrack csp">
                <img src={ForwordIconf} alt="BackIcon" />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              pt={3}
              className="bottomText_info"
            >
              <Typography onClick={() => setOpenPlaybackPop(true)} style={{ cursor: "pointer" }} className="bottomText">
                Set Playback Speed
              </Typography>
            </Grid>
          </div>
        </Grid>
      </div>
      {openPlaybackPop && (
        <div ref={playBackRef} className="playback-speed-wrap">
          <div
            onClick={() => setOpenPlaybackPop(false)}
            className="close-bar"
          />
          <div className="playback-text">Set Playback speed</div>
          <div className="slider-wrap">
            <Slider
              value={oSpeedMap[player.playbackRate]}
              defaultValue={30}
              getAriaValueText={(v) => v}
              aria-labelledby="discrete-slider-restrict"
              step={null}
              marks={playBackSliderMarks}
              min={10}
              max={60}
              valueLabelDisplay="auto"
              color="primary"
              onChange={handlePlaybackRateChange}
            />
          </div>
        </div>
      )}
    </Dialog>
  );
}
