import { createContext } from 'react';

export const initPlayerState = {
  player: null,
  playing: false,
  volume: 0.8,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  file: null,
  fullScreen: false,
  mode: 'full',
  playlist: [],
  likedPods: []
};

export const PlayerContext = createContext(
  initPlayerState // default value
);
