import React from 'react';
const useBaseUrl = () => {
    const [originUrl, setOriginUrl] = React.useState("#");
    React.useEffect(() => {
        if (window) {
             
            window.location.origin.includes("localhost:3001") ? setOriginUrl("http://localhost:3001/en/April/29") : setOriginUrl(window.location.origin);

        }

    },[])
    return {originUrl};
}
export default useBaseUrl;