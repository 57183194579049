import React, { createContext, useRef } from "react";
import "../styles/globals.css";
import { UserContext } from "../components/podcasts/userContext";
import Main from "../components/Main";
import Head from 'next/head';


// //Contexts
export const bottomNavigationContext = createContext("");
export const notificationCardContext = createContext("");
export const topicContext = createContext("");
export const dateContext = createContext("");

function MyApp(props) {
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>
      <Head>

      </Head>
      <UserContext.Provider
        value={{ scrollRef }}
      >
        <Main scrollTo={500} {...props} />
      </UserContext.Provider>
    </>
  );
}

export default MyApp;
