import { createGlobalStyle} from "styled-components"

export const lightTheme = {
  body: '#FFF',
  text: '#363537',
  toggleBorder: '#FFF',
  background: '#363537',
}

export const darkTheme = {
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
  background: '#999',
}

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    ${'' /* transition: all 0.50s linear; */}
  }
  p{
      color : ${({theme})=>theme.text}
  }
  .has-dropcap::first-letter{
    color : ${({theme})=>theme.text}
  }
  blockquote{
    color : ${({theme})=>theme.text}
  }
  .img-description{
    color : ${({theme})=>theme.text}
  }
`