import {
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    WhatsappIcon,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";

const Share = ({ url, title, ...rest }) => {
  return (
    <div style={rest.style || {}}>
      <WhatsappShareButton
        url={
          `${url}`
        }
        title={title}
        // style={{ height: "10px", width: "30px" }}
      >
        {rest.wIcon || <WhatsappIcon style={{ color: "#777777", fontSize: "10px", width: 37, height: 37 }} />}
      </WhatsappShareButton>

      <FacebookShareButton
        url={
          `${url}`
        }
        quote={title}
        // style={{ height: "10px", width: "30px" }}
      >
        {rest.FbIcon || <FacebookIcon style={{ color: "#777777", fontSize: "10px", width: 37, height: 37 }} />}
      </FacebookShareButton>

      {/* <TwitterShareButton
        url={
          `${url}`
        }
        quote={title}
        // style={{ height: "10px", width: "30px" }}
      >
        {rest.tIcon || <TwitterIcon style={{ color: "#777777", fontSize: "10px", width: 37, height: 37 }} />}
      </TwitterShareButton> */}

      {/* <LinkedinShareButton
        url={
          `${url}`
        }
        quote={title}
        // style={{ height: "10px", width: "30px" }}
      >
        {rest.lIcon || <LinkedinIcon style={{ color: "#777777", fontSize: "10px", width: 37, height: 37 }} />}
      </LinkedinShareButton> */}
    </div>
  );
};

export default Share;
