import axios from "axios";
import config from "../../config/config";

export const getPodcasts = async ({ cuser }) => {
  let authUser = {};
  try {
    authUser = JSON.parse(cuser);
  } catch (err) {
    console.log("server err", err);
  }
  let user = { data: authUser };
  if (!Object.keys(user.data).length) {
    user = await axios.post(`${config.baseUrl}/api/users/account/login/guest`, {
      uid: (Math.random() + "").split(".")[1],
      uid_type: "web_id",
    });
  }
  const podcasts = await axios.get(
    `${config.baseUrl}/api/stories/podcasts?limit=100000&offset=0`,
    {
      headers: {
        Authorization: `token ${user.data.token}`,
      },
    }
  );
  const data = await podcasts.data;
  return { data, user: user.data };
};

export const getPodcastsSSG = async () => {
  const user = await axios.post(
    `${config.baseUrl}/api/users/account/login/guest`,
    {
      uid: (Math.random() + "").split(".")[1],
      uid_type: "web_id",
    }
  );
  // console.log({ user });
  const podcasts = await axios.get(
    `${config.baseUrl}/api/stories/podcasts?limit=100000&offset=0`,
    {
      headers: {
        Authorization: `token ${user.data.token}`,
      },
    }
  );
  const data = await podcasts.data;
  // console.log('user ' + JSON.stringify(data));
  return { data, user: user.data };
};

export const getPodcast = async ({ cuser, pid }) => {
  let authUser = {};
  try {
    authUser = JSON.parse(cuser);
  } catch (err) {
    console.log("server err", err);
  }
  let user = { data: authUser };
  if (!Object.keys(user.data).length) {
    user = await axios.post(`${config.baseUrl}/api/users/account/login/guest`, {
      uid: (Math.random() + "").split(".")[1],
      uid_type: "web_id",
    });
  }
  const podcasts = await axios.get(
    `${config.baseUrl}/api/stories/podcast-audios/${pid}`,
    {
      headers: {
        Authorization: `token ${user.data.token}`,
      },
    }
  );
  const data = await podcasts.data;
  return { data, user: user.data };
};

export const hitLike = async ({ token, pid }) => {
  const response = await axios.put(
    `${config.baseUrl}/api/stories/podcast-audios/${pid}/like/toggle`,
    {},
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  );

  return response;
};

export const incPlayCount = async ({ token, pid }) => {
  const response = await axios.put(
    `${config.baseUrl}/api/stories/podcast-audios/${pid}/play`,
    {},
    {
      headers: {
        Authorization: `Token ${token}`,
      },
    }
  );

  return response;
};

export const getPodcastSSG = async ({ pid }) => {
  const user = await axios.post(
    `${config.baseUrl}/api/users/account/login/guest`,
    {
      uid: (Math.random() + "").split(".")[1],
      uid_type: "web_id",
    }
  );
  const podcast = await axios.get(
    `${config.baseUrl}/api/stories/podcast-audios/slug?q=${pid}`,
    {
      headers: {
        Authorization: `token ${user.data.token}`,
      },
    }
  );
  const data = await podcast.data;
  return { data };
};

export const loginGuest = async () => {
  try {
    const user = await axios.post(
      `${config.baseUrl}/api/users/account/login/guest`,
      {
        uid: (Math.random() + "").split(".")[1],
        uid_type: "web_id",
      }
    );
    return user;
  } catch (err) {
    console.log(err);
  }
};

export const getLikedPods = async (authUser) => {
  try {
    const user = typeof authUser === "string" ? JSON.parse(authUser) : authUser;
    const likedPods = await axios.get(
      `${config.baseUrl}/api/stories/podcast-audios/likes`,
      {
        headers: {
          Authorization: `token ${user.token}`,
        },
      }
    );
    return likedPods;
  } catch (err) {
    console.log(err);
  }
};
