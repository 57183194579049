export const GA_TRACKING_ID = "G-T80N5YB4F0"
export const GA_TRACKING_ID_GIONEE = "G-Y3REY1JHQH"
export const GA_TRACKING_ID_HUAWEI = "G-KTXX13TBQW"
export const GA_TRACKING_ID_MMX = "G-DCP07DBEMX"
export const GA_TRACKING_ID_BB = "G-E16PWE6XJN"
export const GA_TRACKING_ID_PAYTM = "G-CS0FB4X79X"
export const GA_TRACKING_ID_OPPO = "G-X7RMR46644"
export const GA_TRACKING_ID_OPPO_BROWSER = "G-4BF417743V"

export const GA_TRACKING_ID_AMP_GENERIC = "UA-180483140-4";
export const GA_TRACKING_ID_UA_OPPO_BROWSER = "UA-180483140-5";
export const GA_TRACKING_ID_UA_GENERIC = "UA-180483140-6";
export const GA_TRACKING_ID_UA_REALME_BROWSER = "UA-180483140-7";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url, count) => {
  // window.gtag('config', GA_TRACKING_ID, {
  //   'send_page_view': false
  // })
  const value = window.history.length > 1 ? false : true;
  url = window.location.search;
  const utmSource = new URLSearchParams(url).get("utm_source")

  if (utmSource === "gionee") {
    window.gtag('config', GA_TRACKING_ID_GIONEE, {
      'send_page_view': value
    })
  }
  if (utmSource === "huawei") {
    window.gtag('config', GA_TRACKING_ID_HUAWEI, {
      'send_page_view': value
    })
  }
  if (utmSource === "utm_source=mmx") {
    window.gtag('config', GA_TRACKING_ID_MMX, {
      'send_page_view': value
    })
  }
  if (utmSource === "bb") {
    window.gtag('config', GA_TRACKING_ID_BB, {
      'send_page_view': value
    })
  }
  if (utmSource === "paytm") {
    window.gtag('config', GA_TRACKING_ID_PAYTM, {
      'send_page_view': value
    })
  }
  if (utmSource === "oppo") {
    window.gtag('config', GA_TRACKING_ID_OPPO, {
      'send_page_view': value
    })
  }

  if (utmSource === "oppobrowser") {
    window.gtag('config', GA_TRACKING_ID_OPPO_BROWSER, {
      'send_page_view': value
    })
  }




}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const trackEvent = ({ event_name, data_json }) => {
  window.gtag('event', event_name, data_json)
}