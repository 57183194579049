export const withLocalStorage = (WrappedComponent) => (props) => {
    
  const parse = (value) => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  const getItem = (key) => {
    if (!localStorage) return;
    const item = localStorage.getItem(key);
    if (item) return parse(item);
  };

  const setItem = (key, item) => {
    if (!localStorage) return;
    localStorage.setItem(key, JSON.stringify(item));
  };

  const removeItem = (key) => {
    if (!localStorage) return;
    localStorage.removeItem(key);
  };

  return (
    <WrappedComponent
      {...props}
      setItem={setItem}
      getItem={getItem}
      removeItem={removeItem}
    />
  );
};
