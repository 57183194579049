import React, { useEffect, useState } from 'react';
import useBaseUrl from './useBaseUrl';

const useGetUTM = () => {
  const [mounted, setMounted] = useState(false);
  let utmSource, utmMedium, utmCampaign;
  useEffect(() => {
    setMounted(true);
  }, [])
  if (mounted) {
    utmSource = localStorage.getItem("utm_source");
    utmMedium = localStorage.getItem("utm_medium");
    utmCampaign = localStorage.getItem("utm_campaign")
  }
  const { originUrl } = useBaseUrl();
  const utmAppendString = (utmSource !== "null" && utmSource !== undefined && utmSource !== 'undefined') || (utmCampaign !== "null" && utmCampaign !== undefined && utmCampaign !== 'undefined') ? `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}` : '';
  const urlString = originUrl.concat(utmAppendString);
  return { urlString, utmAppendString, utmSource, utmMedium, utmCampaign };
}
export default useGetUTM;