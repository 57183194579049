import React, { Component } from "react";
import ReactPlayer from "react-player";
import { initPlayerState, PlayerContext } from "./playerContext";
import PlayerFullscreen from "./PlayerFullscreen";
import PlayerMini from "./PlayerMini";
import { hitLike, incPlayCount } from "../podcasts/service";
import router from "next/router";
import { withLocalStorage } from "../../common/hooks/withLocalStorage";
import { USER } from "../Main";

class Player extends Component {
  state = {
    pip: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    playbackRate: 1.0,
    loop: false,
  };

  playAudioProps = {
    played: 0,
    loaded: 0,
    pip: false,
    playing: true,
    mode: "full",
  };

  setPlayerState = () => { };

  handlePlayPause = () => {
    this.setPlayerState((ps) => {
      return { ...ps, playing: !ps.playing };
    });
  };

  handleStop = () => {
    this.setPlayerState((ps) => ({ ...ps, playing: false, url: null }));
  };

  handleSetPlaybackRate = (e) => {
    this.setPlayerState((ps) => ({
      ...ps,
      playbackRate: parseFloat(e.target.value),
    }));
  };

  handleOnPlaybackRateChange = (speed) => {
    this.setPlayerState((ps) => ({ ...ps, playbackRate: parseFloat(speed) }));
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    this.setPlayerState((ps) => ({ ...ps, playing: true }));
    if (this.playerState.played === 0) {
      this.incPlayC()
    }
  };

  handleEnablePIP = () => {
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    this.setState({ pip: false });
  };

  handlePause = () => {
    this.setPlayerState((ps) => ({ ...ps, playing: false }));
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true, realInc: false });
  };

  handleSeekChange = (e) => {
    this.setPlayerState((ps) => ({
      ...ps,
      played: parseFloat(e.target.value),
    }));
  };

  incPlayC = async () => {
    try {
      const user = this.props.getItem(USER);
      const pid = this.playerState.file?.audios?.[0]?.id;
      if (user?.token) {
        const { data: podcast } = await incPlayCount({
          token: user.token,
          pid,
        });
        this.setPlayerState((ps) => ({
          ...ps,
          file: { audios: [podcast] },
          playlist: ps.playlist.map((pl) =>
            pl?.audios?.[0]?.id === podcast.id ? { audios: [podcast] } : pl
          ),
        }));
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setPlayerState((ps) => ({ ...ps, ...state }));
    }
  };

  closePlayer = () => {
    this.setPlayerState((ps) => ({
      ...initPlayerState,
      playlist: ps.playlist,
    }));
  };

  fastForward = (way, timeSeconds, playedSeconds) => {
    this.setState({ seeking: false });
    const seekToPOS =
      way === "back"
        ? playedSeconds - timeSeconds
        : playedSeconds + timeSeconds;
    this.player.seekTo(seekToPOS, "seconds");
  };

  handleEnded = () => {
    const randomPodcastIdx = this.getRandomArbitrary(0, this.playlist.length);
    const podcast = this.playlist[randomPodcastIdx];
    if (podcast) {
      this.setPlayerState((player) => ({
        ...player,
        url: podcast.audios?.[0]?.audio_url,
        file: podcast,
        ...this.playAudioProps,
      }));
    }
  };

  handleDuration = (duration) => {
    this.setPlayerState((ps) => ({ ...ps, duration }));
  };

  handleClickFullscreen = () => {
    // screenfull.request(findDOMNode(this.player))
  };

  onFullScreen = (slug) => {
    router.push(
      {
        pathname: `/podcast/${slug}`,
      },
      undefined,
      { shallow: true }
    );
    // this.setPlayerState((ps) => ({ ...ps, mode: "full" }));
  };

  ref = (player) => {
    this.player = player;
  };

  onLike = (pid) => async (e) => {
    e.stopPropagation();
    try {
      const user = this.props.getItem(USER);
      if (user?.token) {
        this.setPlayerState((ps) => {
          const playingAudio = ps.file.audios[0];
          let likedPods = [...ps.likedPods];
          if (likedPods.includes(playingAudio.id)) {
            likedPods = likedPods.filter(id => id !== playingAudio.id)
          } else {
            likedPods.push(playingAudio.id)
          }
          return {
            ...ps,
            likedPods
          };
        });
        await hitLike({ token: user.token, pid });
        // const { data: podcast } = res;
        // const { data: { liked_ids: likedPods } } =  await getLikedPods(user);
        // this.setPlayerState((ps) => ({
        //   ...ps,
        //   likedPods,
        // }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  handleNext = () => {
    const podcast =
      this.playlist[
      this.playlist.findIndex(
        (pod) =>
          pod?.audios?.[0]?.id === this.playerState.file?.audios?.[0]?.id
      ) + 1
      ];
    if (podcast) {
      this.setPlayerState((player) => ({
        ...player,
        url: podcast.audios?.[0]?.audio_url,
        file: podcast,
        ...this.playAudioProps,
      }));
    }
  };

  handlePrev = () => {
    const podcast =
      this.playlist[
      this.playlist.findIndex(
        (pod) =>
          pod?.audios?.[0]?.id === this.playerState.file?.audios?.[0]?.id
      ) - 1
      ];
    if (podcast) {
      this.setPlayerState((player) => ({
        ...player,
        url: podcast.audios?.[0]?.audio_url,
        file: podcast,
        ...this.playAudioProps,
      }));
    }
  };

  handlePlaybackRateChange = (e, v) => {
    const speedMap = {
      10: 0.25,
      20: 0.5,
      30: 1,
      40: 1.25,
      50: 1.5,
      60: 2
    }

    this.setPlayerState((player) => ({
      ...player,
      playbackRate: speedMap[v],
    }));
  };

  render() {
    const {
      // playing,
      controls,
      light,
      volume,
      muted,
      loop,
      // played,
      // loaded,
      // duration,
      // playbackRate,
      pip,
    } = this.state;

    return (
      <PlayerContext.Consumer>
        {({ player, setPlayerState }) => {
          this.setPlayerState = setPlayerState;
          this.playlist = player.playlist;
          this.playerState = player;
          return (
            <>
              <ReactPlayer
                ref={this.ref}
                className="react-player"
                width="100%"
                height="100%"
                url={player.url}
                pip={pip}
                playing={player.playing}
                controls={controls}
                light={light}
                loop={loop}
                playbackRate={player.playbackRate}
                volume={volume}
                muted={muted}
                onReady={() => console.log("onReady")}
                onStart={() => console.log("onStart")}
                onPlay={this.handlePlay}
                onEnablePIP={this.handleEnablePIP}
                onDisablePIP={this.handleDisablePIP}
                onPause={this.handlePause}
                onPlaybackRateChange={this.handleOnPlaybackRateChange}
                onSeek={(e) => console.log("onSeek", e)}
                onEnded={this.handleEnded}
                onError={(e) => console.log("onError", e)}
                onProgress={this.handleProgress}
                onDuration={this.handleDuration}
                progressInterval={100}
              />
              {player.mode === "full" && player.file && (
                <PlayerFullscreen
                  player={player}
                  setPlayerState={this.setPlayerState}
                  handlePlayPause={this.handlePlayPause}
                  handleSetPlaybackRate={this.handleSetPlaybackRate}
                  handleSeekChange={this.handleSeekChange}
                  handleSeekMouseDown={this.handleSeekMouseDown}
                  handleSeekMouseUp={this.handleSeekMouseUp}
                  fastForward={this.fastForward}
                  onLike={this.onLike}
                  handleNext={this.handleNext}
                  handlePrev={this.handlePrev}
                  handlePlaybackRateChange={this.handlePlaybackRateChange}
                />
              )}
              {player.mode !== "full" && player.file && (
                <PlayerMini
                  player={player}
                  handlePlayPause={this.handlePlayPause}
                  closePlayer={this.closePlayer}
                  onFullScreen={this.onFullScreen}
                  onLike={this.onLike}
                />
              )}
            </>
          );
        }}
      </PlayerContext.Consumer>
    );
  }
}

export default withLocalStorage(Player);
